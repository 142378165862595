import React, { Component } from 'react'

export default class Facebook extends Component {
    componentDidMount() {
        if (window.FB) {
            window.FB.getLoginStatus(function(response) {
                // if (response.status === 'connected') {
                //   // The user is logged in and has authenticated your
                //   // app, and response.authResponse supplies
                //   // the user's ID, a valid access token, a signed
                //   // request, and the time the access token 
                //   // and signed request each expire.
                //   var uid = response.authResponse.userID;
                //   var accessToken = response.authResponse.accessToken;
                // } else if (response.status === 'not_authorized') {
                    // if (response.status === 'not_authorized')
                    // window.FB.login()
                  // The user hasn't authorized your application.  They
                  // must click the Login button, or you must call FB.login
                  // in response to a user gesture, to launch a login dialog.
                // } else {
                //   // The user isn't logged in to Facebook. You can launch a
                //   // login dialog with a user gesture, but the user may have
                //   // to log in to Facebook before authorizing your application.
                // }
    
                // console.log(response);
               });
        window.FB.XFBML.parse();
    }  
}
    componentDidUpdate() {
        if (window.FB) {
          window.FB.XFBML.parse();
      }
      }
    render() {
        var {id} = this.props
        console.log(id);
        return (
            <div style={{marginLeft:"33%"}}>
            
                 <div id="fb-root"></div>
                <div
                    id="facebook"
                    className="fb-comments"
                    data-href={"https://userlkdt.now.sh/chitietsanpham"+id}
                    data-numposts="5"></div>
           
            </div>
        )
    }
}
