import React, { Component } from 'react'

export default class Banner extends Component {
    render() {
        return (
            <div className="banner">
                <div className="banner_background" style={{ backgroundImage: 'url(images/banner_background.jpg)' }} />
                <div className="container fill_height">
                    <div className="row fill_height">
                        <div className="banner_product_image"><img src="images/banner_product.png" alt="true" /></div>
                        <div className="col-lg-5 offset-lg-4 fill_height">
                            <div className="banner_content">
                                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
