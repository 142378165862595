import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'
import _ from 'lodash'
import PubNubReact from 'pubnub-react'
import scrollToComponent from 'react-scroll-to-component'
export default class MainHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search:"",
            quanity:0,
            isUpdate:false
        }
        this.pubnub = new PubNubReact({
            publishKey: 'pub-c-d04eee1c-f283-4557-b435-fde8d17b5976',
            subscribeKey: 'sub-c-b790c38c-9942-11e9-9cd2-86061a909544',
            secretKey: 'sec-c-MGQ1NWYxZGItYzc0Mi00MGE4LWIwNDEtZTBlNzRlYTAxMDRj'
        });
        this.pubnub.init(this);
        // this.componentWillMount = this.componentWillMount.bind(this)
    }

    checkMess = (message) => {
        if(message.message.title === "AddToCart") {
            var qty= this.state.quanity + message.message.qty
            this.setState({
                quanity:qty
            })
        }
        if(message.message.title === "DeleteInCart") {
            console.log("del");
            this.setState({
                quanity:0
            })
        }
    }

    componentWillMount() {
        this.pubnub.addListener({
            message: (message) => {
                this.checkMess(message)
            }
        })
        
        this.pubnub.subscribe({
            channels: ['cart']
        });
        var cart   = JSON.parse(localStorage.getItem('CART'))
        var qty = _.sumBy(cart, function (o) {
            return o.quanity;
        }); 
        this.setState({
            quanity:qty
        })
      }

    searchChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    componentWillReceiveProps(nextProps) {
        console.log(nextProps)
    }
    componentDidMount() {
        var cart   = JSON.parse(localStorage.getItem('CART'))
        let qty = _.sumBy(cart, function (o) {
            return o.quanity;
        }); 
        this.setState({
            quanity:qty
        })
    }
    render() {
       
        return (
            <section ref={(section) => { this.Green = section; }}>
                <header className="header">
                {/* <TopBar /> */}
                <div className="header_main">
                    <div className="container">
                        <div className="row">
                            {/* Logo */}
                            <div className="col-lg-2 col-sm-3 col-3 order-1">
                                <div className="logo_container">
                                <div className="logo"><a><NavLink to="/">OneTech</NavLink></a></div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 order-lg-2 order-3 text-lg-left text-right">
                                {/* <div className="header_search">
                                    <div className="header_search_content">
                                        <div>
                                            <form className="header_search_form clearfix">
                                                <input name="search" value= {this.state.search} onChange= {this.searchChange} type="search" required="required" className="header_search_input" placeholder="Tìm kiếm sản phẩm..." />
                                                
                                                <button type="submit" className="header_search_button trans_300" value="Submit"><img src="images/search.png" alt ="true"/></button>
                                            </form>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            
                            <div className="col-lg-4 col-9 order-lg-3 order-2 text-lg-left text-right">
                                <div className="wishlist_cart d-flex flex-row align-items-center justify-content-end">
                                    {/* Cart */}
                                    <div className="cart">
                                        <div className="cart_container d-flex flex-row align-items-center justify-content-end">
                                            <div className="cart_icon">
                                                <img src="images/cart.png" alt="true" />
                                                <div className="cart_count"><span>{this.state.quanity}</span></div>
                                            </div>
                                            <div className="cart_content">
                                                <div className="cart_text"><NavLink to="/giohang">Giỏ hàng</NavLink></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
              
            </header>
            </section>
            
        )
    }
}
