import axios from 'axios'

const URL = "https://cors-anywhere.herokuapp.com/http://139.162.28.184:3456/api/v1";
var header = {}
if(localStorage.getItem('user') !== null) {
  header = {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user'))
            .token
    };
}


export default function apiCaller(endpoint,method,data=null,headers=header) {
    return axios({
        method: method,
        url: `${URL}/${endpoint}`,
        data:data,
        headers:headers
    }).catch(err=>{
        console.log(err);
    })
}
