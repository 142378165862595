import React, { Component } from 'react'
import MainHeader from './components/Header/MainHeader';
import Footer from './components/Footer/Footer';
import MainNavigation from './components/Navigation/MainNavigation';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import BestSaler from './components/Bestsaler/BestSaler'
import routes from './routes'
import Introduce from './components/Introduce';
import ChatSupport from './components/Navigation/ChatSupport';


export default class App extends Component {
    showContents = (routes) => {
        var result = null;
        if (routes.length > 0) {
            result = routes.map((route, index) => {
                return (<Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.main} />)
            })

        }
        return result;
    }


    render() {
        return (
            <Router>
                <div className="super_container">
                    <MainHeader />
                    <MainNavigation />
                    <Introduce />
                   
                    <div>
                        <Switch>
                            {this.showContents(routes)}
                        </Switch>
                    </div>

                    <br />   <br /><br />   <br />
                    <BestSaler />
                    <Footer />
                </div>
            </Router>

        )
    }
}
