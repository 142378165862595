import React from 'react'
import DetailProduct from './components/Navigation/DetailProduct';
import Shopping from './components/Navigation/Shopping';
import Cart from './components/Cart.js';
import HomePage from './components/HomePage';



const routes = [
    {
        path:'/',
        exact:true,
        main:({match}) => <HomePage match={match}/>
    },
    {
        path:'/sanpham/:category',
        exact:true,
        main:({match}) => <Shopping match={match}/>
    },
    {
        path:'/sanpham',
        exact:true,
        main:({match}) => <Shopping match={match}/>
    },
	{
        path:'/giohang',
        exact:true,
        main:() => <Cart/>
    },
    
    {
        path:'/chitietsanpham/:id',
        exact:false,
        main:({match}) => <DetailProduct match={match}/>
    },
    
    
    
]   
export default routes;