import React, {Component} from 'react';
export default class Footer extends Component {
    render() {
        return (
            <footer className="footer">
  <div className="container">
    <div className="row">
      <div className="col-lg-3 footer_col">
        <div className="footer_column footer_contact">
          <div className="logo_container">
            <div className="logo"><i>OneTech</i></div>
          </div>
          <div className="footer_title">Bạn có thắc mắc? Liên hệ 24/7</div>
          <div className="footer_phone">+38 068 005 3570</div>
          <div className="footer_contact_text">
            <p>Ninh Kiều - Cần Thơ</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

        );
    }
}
