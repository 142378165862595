import React, { Component } from 'react';
import './cart.css'
import scrollToComponent from 'react-scroll-to-component';
import swal from '@sweetalert/with-react'
import { StripeProvider } from 'react-stripe-elements';
import './payment.css'
import { Elements } from 'react-stripe-elements';
import InjectedCheckoutForm from './CheckoutForm';
import Script from 'react-load-script';
import PubNubReact from 'pubnub-react'
import ScrollUpButton from "react-scroll-up-button";
import Currency from 'react-currency-formatter';
import {NavLink} from 'react-router-dom'
class Cart1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quanity: 0,
            products: [],
            total: 0,
            txtName: "",
            txtAddress: "",
            txtEmail: "",
            txtPhone: "",
            isVaild:false,
            country: '',
            city: '',
            district: '',
            ward: '',
            query: '',
            type: [],
        }
        this.pubnub = new PubNubReact({
            publishKey: 'pub-c-d04eee1c-f283-4557-b435-fde8d17b5976',
            subscribeKey: 'sub-c-b790c38c-9942-11e9-9cd2-86061a909544',
            secretKey: 'sec-c-MGQ1NWYxZGItYzc0Mi00MGE4LWIwNDEtZTBlNzRlYTAxMDRj'
        });
        this.pubnub.init(this);
    }
    componentDidMount() {
        
        var store_info = JSON.parse(localStorage.getItem('CART'))
        if(store_info) {
            this.setState({ products: store_info })
        }
        scrollToComponent(this.Green, {offset: 0,align: 'middle',duration: 500,ease: 'inExpo'})
    }
    
    inputChange = (e) => {
        var { name, value } = e.target;
        this.setState({ [name]: value })
    }
    // onCheckOut = () => {
    //     $("#modalLRForm").modal("show");
    // }
    quanity_click = (id, e) => {
        var products = this.state.products
        console.log(id);
        var quantity = products[id].quanity;
        if (e.target.id === "up") {
            quantity = products[id].quanity + 1;
            products[id].quanity = quantity
            this.setState({ products: products })
        } else {
            quantity = products[id].quanity - 1;
            if (quantity < 1) {
                // swal('Vui lòng nhập số lượng sản phẩm lớn hơn 1')
                swal("Vui lòng nhập số lượng sản phẩm lớn hơn 1")
                quantity = 1
            }
            products[id].quanity = quantity
            this.setState({ products: products })
        }
    }

    onCancelItem = (index) => {
        swal({ text: "Bạn có muốn xóa sản phẩm này khỏi giỏ hàng không?", icon: "warning",confirmButtonText:"Xác nhận", buttons:true }).then((willDelete) => {
            if (willDelete) {
                var update_cart = this.state.products
                this.pubnub.publish({
                    message: {
                        title : "AddToCart",
                        qty: -update_cart[index].quanity
                    },
                    channel: 'cart'
                });
                
                update_cart.splice(index, 1);
                swal("Sản phẩm của bạn đã được xóa", { icon: "success" });
                this.setState({ products: update_cart })
                localStorage.setItem("CART", JSON.stringify(update_cart))
                
            } else { }
        });
        
    }
    // GG map
    handleScriptLoad = () => {
        // Declare Options For Autocomplete 
        var options = { types: ['(cities)'] };
    
        // Initialize Google Autocomplete 
        /*global google*/
        this.autocomplete = new google.maps.places.Autocomplete(
          document.getElementById('autocomplete'),
          options);
        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed',
          this.handlePlaceSelect);
      }
    
      handlePlaceSelect = () => {
    
        // Extract City From Address Object
        var addressObject = this.autocomplete.getPlace();
        var address = addressObject.address_components;
        var p_type = "";
        address.map((obj, i) => {
          p_type = address[i].types[0];
          
          if (p_type === "locality" || p_type ===  "administrative_area_level_2") {
            this.setState({
              district: obj.long_name
            })
          }
          if (p_type === "administrative_area_level_3") {
            this.setState({
              ward: obj.long_name
            })
          }
          if (p_type === "administrative_area_level_1") {
            this.setState({
              city: obj.long_name
            })
          }
            if (p_type === "country") {
              this.setState({
                country: obj.long_name
              })
            }
      })
      }
    //   End gg map

    render() {
        
        scrollToComponent(this.Green, {offset: 0,align: 'middle',duration: 500,ease: 'inExpo'})
        var { txtAddress, txtEmail, txtName, txtPhone,isVaild } = this.state
        if(txtAddress!=="" && txtEmail !== "" && txtName !== "" && txtPhone !=="") {
            isVaild = true
            var customer = {
                name:txtName,
                address:txtAddress,
                phone:txtPhone,
                email:txtEmail
            }
        }
        var products = []
        products = this.state.products
        var map_info = []
        var sum = 0
        var sum_Total = 0
        var qty_sum = 0
        if (products.length > 0) {
            var map_info = products.map((item_info, index) => {
                var qty_item = item_info.quanity
                sum = item_info.price * item_info.quanity
                qty_sum += qty_item
                sum_Total += sum

                return (
                    <div key={index}>
                       <ScrollUpButton />
                        <div className="row">
                        <div className="form-group">
                                    <div className="col-sm-3 col-xs-3">
                                        {
                                            (item_info.images > 0) 
                                            ? <img className="img-responsive" src= {item_info.images[0]}/>
                                            : <img className="img-responsive" src= "https://via.placeholder.com/300"/>
                                        }
                                        
                                    </div>
                                    <div className="col-sm-3 col-xs-3">
                                        <div className="col-xs-12">{item_info.title}</div>
                                  
                                        <div className="cart_item_quantity cart_info_col">
                                                          
                                                            <div className="cart_item_text">
                                                           
                                                                <div className="product_quantity clearfix"style={{width:"85%"}}>
                                                                <span>
                                                                    {item_info.quanity}
                                                                </span>
                                                                    {/* <input type="text" value={item_info.quanity}/> */}
                                                                    <div className="quantity_buttons" >
                                                                    <div
                                                                            id="up"
                                                                            onClick={(e)=>this.quanity_click(index,e)}
                                                                            className="quantity_inc quantity_control"><i className="fas fa-chevron-up"/></div>
                                                                        <div
                                                                            id="down"
                                                                            onClick={(e)=>this.quanity_click(index,e)}
                                                                            className="quantity_dec quantity_control"><i className="fas fa-chevron-down"/></div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            {/* End Quantity picker */}
                                                            </div>
                                                        </div>
                                    </div>
                                        {/* <div className="col-xs-12"><small>Số lượng:<span>{item_info.quanity}</span></small></div> */}
    
                                    <div className="col-sm-4 col-xs-4">
                                        <div name={"item"+index} style={{border:"none", width:"55%"}} className="cart_item_text"><Currency quantity={sum} currency="VND" locale="vi_VN" pattern="###,### !" decimal="," group="." /></div>
                                        
                                    </div>
                                    <div className="col-sm-2 col-xs-2 ">
                                    <span className="text text-danger" onClick={() => {this.onCancelItem(index,item_info)}}>
                                        <h2><i className="fas fa-times"></i></h2>
                                        </span>
                                    </div>
                                    {/* <div className="form-group"><hr /></div> */}
                    </div>
                        </div>
                        
                    </div>
                    
                  
                )
            })
        }
        else{
            return <section ref={(section) => { this.Green = section; }}>
                 <div className="text-center text-danger"> <h3>Không có sản phẩm nào trong giỏ hàng của bạn</h3><br/>
                 <NavLink to={"/sanpham"}><button> Tiếp tục mua sắm</button></NavLink>
                 </div>
                 
            </section>
           
        }

        return (
<section ref={(section) => { this.Green = section; }}>
            <div  className="container wrapper">
                <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyDNq9txsUkTmsFCXzHgzRSUIA6JzhmXKLM&libraries=places"
          onLoad={this.handleScriptLoad}
        />
                <div className="row cart-head">
                    <div className="container">
                        <div className="row">
                            <p></p>
                        </div>

                        <div className="row">
                            <p></p>
                        </div>
                    </div>
                </div>
                <div className="row cart-body">
                    <form className="form-horizontal"   >
                        <div
                            className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-md-push-6 col-sm-push-6">
                            {/* <!--REVIEW ORDER--> */}
                            <div className="panel">
                                <div className="panel-heading">
                                    Giỏ Hàng
                                                        <div className="pull-right">
                                        <small>
                                            <a className="afix-1"></a>
                                        </small>
                                    </div>
                                </div>
                                <div className="panel-body">

                                    {map_info}
                                    <div className="form-group">
                             
                            </div><hr/>
                            {/* <div className="form-group"><hr /></div> */}
                            <div className="form-group">
                                <div className="col-xs-10 cart_item_text ">
                                    <strong>Tổng số lượng:</strong>
                                    <div className="pull-right">{qty_sum} sản phẩm</div>
                                    
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-xs-10 cart_item_text ">
                                    <strong>Tổng hóa đơn:</strong>
                                    <div className="pull-right">
                                    <span style={{marginRight:"70px"}}><Currency quantity={sum_Total} currency="VND" locale="vi_VN" pattern="###,### !" decimal="," group="." />
                                    </span></div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                        </div>
                        <div
                            className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-md-pull-6 col-sm-pull-6">
                            {/* <!--SHIPPING METHOD--> */}
                            <div className="panel">
                                <div className="panel-heading">Thông tin khách hàng</div>
                                <div className="panel-body">

                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <strong>Họ tên:</strong>
                                        </div>
                                        <div className="col-md-11">
                                            <input
                                                onChange={this.inputChange}
                                                type="text"
                                                name="txtName"
                                                className="form-control"
                                                value={txtName}
                                                required />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <strong>Địa chỉ:</strong>
                                        </div>
                                        <div className="col-md-11">
                                            <input
                                                onChange={this.inputChange}
                                                type="text"
                                                name="txtAddress"
                                                className="form-control"
                                                // value={txtAddress}
                                                required id="autocomplete" className="form-control" placeholder="" aria-describedby="helpId" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <strong>Số điện thoại:</strong>
                                        </div>
                                        <div className="col-md-11"><input
                                            type="text"
                                            onChange={this.inputChange}
                                            name="txtPhone"
                                            className="form-control"
                                            value={txtPhone}
                                            required /></div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <strong>Email:</strong>
                                        </div>
                                        <div className="col-md-11"><input
                                            type="text"
                                            onChange={this.inputChange}
                                            name="txtEmail"
                                            className="form-control"
                                            value={txtEmail}
                                            required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
                <div className="row cart-footer">
        
            </div>
           
         {
             isVaild 
             ?  <StripeProvider apiKey="pk_test_BHUOafmeJtUMRjSTplsjt9Z9">
                    <Elements >
                        <InjectedCheckoutForm customer = {customer} products={this.state.products} />
                    </Elements>
                </StripeProvider>
            : ""
         }
               

            </div>
            </section>
        );
    }
}

export default Cart1;