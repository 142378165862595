import React, { Component } from 'react'
// import ScrollUpButton from "react-scroll-up-button";
import scrollToComponent from 'react-scroll-to-component';
import $ from 'jquery';
// import Chat from './Chat'
import PubNubReact from 'pubnub-react'

export default class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mess :'Hello'
    }
}
    componentDidMount (){
        scrollToComponent(this.HomePage, {offset: 0,align: 'middle',duration: 500,ease: 'inExpo'})
    }
    
    render() {
        scrollToComponent(this.HomePage, {offset: 0,align: 'middle',duration: 500,ease: 'inExpo'})
        return (
            <section ref={(section) => { this.HomePage = section; }}>
                <div className="text-center">
                <h1><strong>CHÀO MỪNG BẠN ĐẾN VỚI WEBSITE LINH KIỆN ĐIỆN TỬ</strong></h1>
              {/* <ScrollUpButton />   */}
              {/* <div onClick={this.openElement} style={{marginLeft:"15px", position:"fixed", cursor:"pointer", display:"block"}}><Chat/></div> */}
            </div>
            </section>
            
        )
    }
}
