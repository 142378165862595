import React, { Component } from 'react'

export default class Introduce extends Component {
    render() {
        return (
            <div className="characteristics">
  <div className="container">
    <div className="row">
      {/* Char. Item */}
      <div className="col-lg-3 col-md-6 char_col">
        <div className="char_item d-flex flex-row align-items-center justify-content-start">
          <div className="char_icon"><img src="images/char_1.png" alt="true" /></div>
          <div className="char_content">
            <div className="char_title">Giao hàng miễn phí</div>
            <div className="char_subtitle">Hóa đơn từ 2.000.000</div>
          </div>
        </div>
      </div>
      {/* Char. Item */}
      <div className="col-lg-3 col-md-6 char_col">
        <div className="char_item d-flex flex-row align-items-center justify-content-start">
          <div className="char_icon"><img src="images/char_2.png" alt="true" /></div>
          <div className="char_content">
            <div className="char_title">Nhanh chóng an toàn</div>
            {/* <div className="char_subtitle">from $50</div> */}
          </div>
        </div>
      </div>
      {/* Char. Item */}
      <div className="col-lg-3 col-md-6 char_col">
        <div className="char_item d-flex flex-row align-items-center justify-content-start">
          <div className="char_icon"><img src="images/char_3.png" alt="true" /></div>
          <div className="char_content">
            <div className="char_title">Uy tín tin cậy</div>
            {/* <div className="char_subtitle">from $50</div> */}
          </div>
        </div>
      </div>
      {/* Char. Item */}
      <div className="col-lg-3 col-md-6 char_col">
        <div className="char_item d-flex flex-row align-items-center justify-content-start">
          <div className="char_icon"><img src="images/char_4.png" alt="true" /></div>
          <div className="char_content">
            <div className="char_title">Chất lượng chính hãng</div>
            {/* <div className="char_subtitle">from $50</div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        )
    }
}
