import React, {Component} from 'react'
import './bestsaler.css'
import $ from 'jquery'
import 'bootstrap'
import apiCaller from '../../utils/apiCaller'
import {NavLink} from 'react-router-dom'
import Currency from 'react-currency-formatter'
import swal from 'sweetalert'
import PubNubReact from 'pubnub-react'
import ChatSupport from '../Navigation/ChatSupport';

export default class BestSaler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docs: []
        }
        this.pubnub = new PubNubReact({publishKey: 'pub-c-d04eee1c-f283-4557-b435-fde8d17b5976', subscribeKey: 'sub-c-b790c38c-9942-11e9-9cd2-86061a909544', secretKey: 'sec-c-MGQ1NWYxZGItYzc0Mi00MGE4LWIwNDEtZTBlNzRlYTAxMDRj'});
        this
            .pubnub
            .init(this);

    }

    onAddToCart = (product) => {
        // var index = -1 index = findProductInCart(product)
        var s_product = {
            id: product._id,
            title: product.title,
            price: product.price,
            desciption: product.desciption,
            quanity: 1,
            images: product.images[0]
        }
        if (localStorage.getItem('CART')) {
            var store = JSON.parse(localStorage.getItem('CART'))
            store.push(s_product);
            localStorage.setItem("CART", JSON.stringify(store))
            swal({text: "Bạn đã thêm sản phẩm vào giỏ hàng thành công", icon: "success"});
        } else {
            var new_store = []
            new_store.push(s_product)
            localStorage.setItem("CART", JSON.stringify(new_store))
            swal({text: "Bạn đã thêm sản phẩm vào giỏ hàng thành công", icon: "success"});
        }
        this
            .pubnub
            .publish({
                message: {
                    title: "AddToCart",
                    qty: 1

                },
                channel: 'cart'
            });
    }
    componentDidMount() {
        $('.next')
            .click(function () {
                $('.carousel').carousel('next');
                return false;
            });
        $('.prev').click(function () {
            $('.carousel').carousel('prev');
            return false;
        });
        apiCaller("products/list?is_active=1&page=6&limit=8", "GET").then(res => {
            this.setState({docs: res.data.docs})
        })
    }

    render() {
        var products = []
        products = this.state.docs

        var map_products_1 = []
        var map_products_2 = []
        var discount = 0

        if (products.length > 0) {
            var product_1 = products.slice(0, 4);
            var product_2 = products.slice(4, 8);
            map_products_1 = product_1.map((product, index) => {
                discount = product.price * (1 - (product.discount / 100))

                return (
                    <div key={index} className="col-lg-3 col-md-6 mb-r">
                        <div  className="card text-center card-cascade narrower">
                            <div
                                style={{
                                height: "200px",
                                width: "250px",
                                backgroundColor:"white"
                            }}
                                className="view overlay hm-white-slight z-depth-1">

                                {product.images.length > 0
                                    ? <img
                                           
                                            width="70%"
                                            style={{
                                            height: "70%",
                                            marginLeft: "auto",
                                            marginRight: "auto"
                                        }}
                                            src={product.images[0]}
                                            className="img-fluid"
                                            alt={product.title}/>
                                    :<img
                                        width="70%"
                                        style={{
                                        height: "70%",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        display: "block"
                                    }}
                                        src="https://via.placeholder.com/200x150?text=no_image"
                                        className="img-fluid"
                                        alt={product.title}/>
}

                                <span>
                                    <div className="mask waves-light waves-effect waves-light"></div>
                                </span>
                            </div>
                            <div className="card-body">
                                <h4 className="card-title">
                                    <strong>
                                        <NavLink to={"/chitietsanpham/" + product.id}>
                                            {product.title}</NavLink>
                                    </strong>
                                </h4>
                            </div>
                                {product.desciption}
                            <div className="card-footer">
                                <div className="row">
                                    <span style={{margin:"0 auto"}} className="center text">
                                        <del><Currency
                                            quantity={product.price}
                                            currency="VND"
                                            locale="vi_VN"
                                            pattern="###,### !"
                                            decimal=","
                                            group="."/></del>
                                    </span>
                                    
                                </div>
                                <div className="row">
                                    
                                        <span style={{margin:"0 auto"}} className="center text-danger">
                                        <Currency
                                            quantity={discount}
                                            currency="VND"
                                            locale="vi_VN"
                                            pattern="###,### !"
                                            decimal=","
                                            group="."/>
                                    </span>
                                    
                                    
                                </div>

                                <div className="row">
                                
                                        <span style={{margin:"0 auto"}} className="center text">
                                        <button
                                            className="btn-floating blue-gradient"
                                            onClick={() => this.onAddToCart(product)}>
                                            <i className="fa fa-shopping-cart"></i>
                                        </button>
                                    </span>
                                
                                    
                                </div>

                            </div>
                        </div>
                    </div>

                )
            })
            map_products_2 = product_2.map((product, index) => {
                discount = product.price * (1 - (product.discount / 100))

                return (
                    <div key={index} className="col-lg-3 col-md-6 mb-r">
                        <div  className="card text-center card-cascade narrower">
                            <div
                                style={{
                                height: "200px",
                                width: "250px",
                                backgroundColor:"white"
                            }}
                                className="view overlay hm-white-slight z-depth-1">

                                {product.images.length > 0
                                    ? <img
                                           
                                            width="70%"
                                            style={{
                                            height: "70%",
                                            marginLeft: "auto",
                                            marginRight: "auto"
                                        }}
                                            src={product.images[0]}
                                            className="img-fluid"
                                            alt={product.title}/>
                                    :<img
                                        width="70%"
                                        style={{
                                        height: "70%",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        display: "block"
                                    }}
                                        src="https://via.placeholder.com/200x150?text=no_image"
                                        className="img-fluid"
                                        alt={product.title}/>
}

                                <span>
                                    <div className="mask waves-light waves-effect waves-light"></div>
                                </span>
                            </div>
                            <div className="card-body">
                                <h4 className="card-title">
                                    <strong>
                                        <NavLink to={"/chitietsanpham/" + product.id}>
                                            {product.title}</NavLink>
                                    </strong>
                                </h4>
                            </div>
                                {product.desciption}
                            <div className="card-footer">
                                <div className="row">
                                    <span style={{margin:"0 auto"}} className="center text">
                                        <del><Currency
                                            quantity={product.price}
                                            currency="VND"
                                            locale="vi_VN"
                                            pattern="###,### !"
                                            decimal=","
                                            group="."/></del>
                                    </span>
                                    
                                </div>
                                <div className="row">
                                    
                                        <span style={{margin:"0 auto"}} className="center text-danger">
                                        <Currency
                                            quantity={discount}
                                            currency="VND"
                                            locale="vi_VN"
                                            pattern="###,### !"
                                            decimal=","
                                            group="."/>
                                    </span>
                                    
                                    
                                </div>

                                <div className="row">
                                
                                        <span style={{margin:"0 auto"}} className="center text">
                                        <button
                                            className="btn-floating blue-gradient"
                                            onClick={() => this.onAddToCart(product)}>
                                            <i className="fa fa-shopping-cart"></i>
                                        </button>
                                    </span>
                                
                                    
                                </div>

                            </div>
                        </div>
                    </div>

                )
            })
        }
        return (
            <div>
                <section className="container p-t-3">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Sản phẩm bán chạy nhất</h1>
                        </div>
                    </div>
                </section>
                <section className="carousel slide" data-ride="carousel" id="postsCarousel">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 text-md-right lead">
                                <span className="btn btn-outline-secondary prev" title="go back"><i className="fa fa-lg fa-chevron-left"/></span>
                                <span className="btn btn-outline-secondary next" title="more"><i className="fa fa-lg fa-chevron-right"/></span>
                            </div>
                        </div>
                    </div>
                    <div className="container p-t-0 m-t-2 carousel-inner">
                        <div className="row row-equal carousel-item active m-t-0">
                            {map_products_1}
                        </div>
                        <div className="row row-equal carousel-item m-t-0">
                            {map_products_2}
                        </div>
                    </div>
                </section>
                <ChatSupport/>
            </div>

        )
    }
}
