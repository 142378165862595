import React, {Component} from 'react'
import apiCaller from '../../utils/apiCaller'
import './product.css'
import swal from 'sweetalert';
import Breadcrumb from './Breadcrumb';
import scrollToComponent from 'react-scroll-to-component'
import PubNubReact from 'pubnub-react'
import $ from 'jquery'
import 'bootstrap'
import Facebook from './Facebook';
import Currency from 'react-currency-formatter';
import _ from 'lodash'

export default class DetailProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quanity: 1,
            product:{},
            images:[],
            isInfo:false,
            cart : [],
            href_Url:""
        }
        this.pubnub = new PubNubReact({
            publishKey: 'pub-c-d04eee1c-f283-4557-b435-fde8d17b5976',
            subscribeKey: 'sub-c-b790c38c-9942-11e9-9cd2-86061a909544',
            secretKey: 'sec-c-MGQ1NWYxZGItYzc0Mi00MGE4LWIwNDEtZTBlNzRlYTAxMDRj'
        });
        this.pubnub.init(this);
    }

    onAddToCart = (product) => {
        var store = JSON.parse(localStorage.getItem('CART'))
         var s_product = {
             id : product._id,
             title: product.title,
             price : product.price,
             desciption : product.desciption,
             quanity : this.state.quanity,
             images : product.images[0]
         }
         var {qty} = this.state
         if(store) {
            var index = _.findIndex(store, function(o) {return o.id == product._id; });
            if(index!==-1){                                                                                         
               store[index].quanity += this.state.quanity
            }
            else{
                store.push(s_product);
            }
             localStorage.setItem("CART",JSON.stringify(store))
             swal({
                text: "Bạn đã thêm sản phẩm vào giỏ hàng thành công",
                icon: "success"
              });
         }
         else {
             var new_store = []
             new_store.push(s_product)
             localStorage.setItem("CART",JSON.stringify(new_store))
             swal({
                text: "Bạn đã thêm sản phẩm vào giỏ hàng thành công",
                icon: "success"
              });
         }
         this.pubnub.publish({
            message: {
                title : "AddToCart",
                qty:this.state.quanity

            },
            channel: 'cart'
        });
     }
     
     
     
     componentDidMount() {
        var id = this.props.match.params.id
        apiCaller("products/details/"+id,"GET").then(res=>{
            this.setState({
                product:res.data,
                images: res.data.images,
                isInfo:true,
                selectedImage:res.data.images[0]
            })
        })
        
        
    }
    
    componentWillReceiveProps() {
        var id = this.props.match.params.id
        apiCaller("products/details/"+id,"GET").then(res=>{
            this.setState({
                product:res.data,
                images: res.data.images,
                isInfo:true,
                selectedImage:res.data.images[0]
            })
        })
    }
 
    imageOnClick = (e) => {
        this.setState({selectedImage: this.state.images[e]})
    }
    quanity_click = (e) => {
        var id = e.target.id;
        (id === "up")
            ? this.setState({
                quanity: this.state.quanity + 1
            })
            : this.setState({
                quanity: this.state.quanity - 1
            })
        if(this.state.quanity < 1) {
            swal("Vui lòng nhập số lượng lớn hơn 1","warning");
            this.setState({
                quanity:1
            })
        }
    }
    render() {
        var id = this.props.match.params.id
        scrollToComponent(this.Green, { offset: 0, align: 'middle', duration: 500, ease: 'inExpo' })    
        var {product,isInfo,images} = this.state;
        var map_images=[]
        if(images.length > 0) {
            map_images = images.map((img,index)=>{
                return (
                   <span key={index}><img onClick = {()=>this.imageOnClick(index)} width="100px" src={img} alt="true" />&nbsp;</span>
                )
            })
        }
        
        return (
            <section ref={(section) => { this.Green = section; }}>
            
            <div>   
                {
                (isInfo)
                ?
                <div className="single_product">
                <div className="container">
                    <Breadcrumb title="Chi tiết sản phẩm" sub_title = {this.state.product.title} />
                    <div className="row">
                        {/* Images */}

                        {/* Selected Image */}
                        <div className="col-lg-5 order-lg-2 order-1">
                            <div className="image_selected"><img width="40%" src={this.state.selectedImage} alt="true"/></div>

                            <div className="wrapper">
                                <div className="scrolls">
                                    {map_images}
                                </div>

                            </div>
                        </div>
                        {/* Description */}
                        <div className="col-lg-5 order-3">
                            <div className="product_description">
                                <div className="product_category">Laptops</div>
                                <div className="product_name">{product.title}</div>
                                <div className="product_text">
                                   {product.desciption}
                                </div>
                                <div className="order_info d-flex flex-row">
                                    <form action="#">
                                        <div
                                            className="clearfix"
                                            style={{
                                            zIndex: 1000
                                        }}>
                                            {/* Product Quantity */}
                                            <div className="product_quantity clearfix">
                                                <span>Số lượng: {this.state.quanity}
                                                </span>
                                               
                                                <div className="quantity_buttons">
                                                    <div
                                                        id="up"
                                                        onClick={this.quanity_click}
                                                        className="quantity_inc quantity_control"><i className="fas fa-chevron-up"/></div>
                                                    <div
                                                        id="down"
                                                        onClick={this.quanity_click}
                                                        className="quantity_dec quantity_control"><i className="fas fa-chevron-down"/></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product_price">
                                        <Currency quantity={product.price} currency="VND" locale="vi_VN" pattern="###,### !" decimal="," group="." />
                                        </div>
                                        <div className="button_container">
                                            <button type="button" className="button cart_button" onClick = {() => {this.onAddToCart(product)}}>Thêm vào giỏ hàng</button>
                                            <div className="product_fav"><i className="fas fa-heart"/></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div
                        style={{
                        backgroundColor: ""
                    }}
                        className="row">
                        <table className="table">
                            <thead>
                                <tr className="thead-light">
                                    <th>
                                        <h2>ĐÁNH GIÁ CHI TIẾT</h2>
                                    </th>
                                    <th>
                                        <h2>THÔNG SỐ KỸ THUẬT</h2>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="60%">
                                        <p>
                                            Là thế hệ đi đầu trong GL Series của MSI, MSI GL73 9SD-276VN luôn tỏ ra là mình
                                            xứng đáng tiên phong cho dòng Laptop Gaming cấu hình “khủng” chinh phục người
                                            dùng của MSI. Sử dụng bộ xử lý Intel hiệu năng cao mới nhất cho công suất, hiệu
                                            năng vượt trội giúp người chơi game được trải nghiệm những cảm xúc tốt nhất. Áp
                                            dụng những công nghệ tiên tiến nhất đến từ MSI với hệ thống tản nhiệt thông
                                            minh, âm thanh sống động, chân thực khiến người chơi được nhập vai đến “lạ
                                            thường”.
                                        </p>
                                        <p>
                                            Là thế hệ đi đầu trong GL Series của MSI, MSI GL73 9SD-276VN luôn tỏ ra là mình
                                            xứng đáng tiên phong cho dòng Laptop Gaming cấu hình “khủng” chinh phục người
                                            dùng của MSI. Sử dụng bộ xử lý Intel hiệu năng cao mới nhất cho công suất, hiệu
                                            năng vượt trội giúp người chơi game được trải nghiệm những cảm xúc tốt nhất. Áp
                                            dụng những công nghệ tiên tiến nhất đến từ MSI với hệ thống tản nhiệt thông
                                            minh, âm thanh sống động, chân thực khiến người chơi được nhập vai đến “lạ
                                            thường”.
                                        </p>
                                        <p>
                                            Là thế hệ đi đầu trong GL Series của MSI, MSI GL73 9SD-276VN luôn tỏ ra là mình
                                            xứng đáng tiên phong cho dòng Laptop Gaming cấu hình “khủng” chinh phục người
                                            dùng của MSI. Sử dụng bộ xử lý Intel hiệu năng cao mới nhất cho công suất, hiệu
                                            năng vượt trội giúp người chơi game được trải nghiệm những cảm xúc tốt nhất. Áp
                                            dụng những công nghệ tiên tiến nhất đến từ MSI với hệ thống tản nhiệt thông
                                            minh, âm thanh sống động, chân thực khiến người chơi được nhập vai đến “lạ
                                            thường”.
                                        </p>

                                    </td>
                                    <td width="40%">
                                        <table className="table table-striped">
                                            <tbody>
                                                <tr>

                                                    <td>Thời gian bảo hành</td>
                                                    <td>
                                                        24</td>
                                                </tr>
                                                <tr>
                                                    <td>Thương hiệu</td>
                                                    <td>MSI</td>
                                                </tr>
                                                <tr>
                                                    <td>Series Laptop</td>
                                                    <td>GAMING</td>
                                                </tr>
                                                <tr>
                                                    <td>Màn hình</td>
                                                    <td>17 Ich</td>
                                                </tr>

                                                <tr>
                                                    <td>CPU</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Ram</td>
                                                    <td>*700H</td>
                                                </tr>
                                                <tr>
                                                    <td>Chip Đồ họa</td>
                                                    <td>GTX</td>
                                                </tr>
                                                <tr colSpan="2">
                                                    <td>
                                                        <button className="btn-info">Xem đầy đủ</button>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>

                </div>
               
            </div>
            
            : "" 

            }
            <div className="row">
                <Facebook id={id} />
            </div>
            </div>
            </section>

        )
    }
}
