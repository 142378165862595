import React from 'react';
import {injectStripe} from 'react-stripe-elements';
import {CardElement} from 'react-stripe-elements';
import apiCaller from '../utils/apiCaller'
import swal from 'sweetalert';
import {Redirect} from 'react-router-dom'
import PubNubReact from 'pubnub-react'
import {NotificationContainer, NotificationManager} from 'react-notifications';
// import './notify.css'

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            isRedirect:false
        }
        this.pubnub = new PubNubReact({
            publishKey: 'pub-c-d04eee1c-f283-4557-b435-fde8d17b5976',
            subscribeKey: 'sub-c-b790c38c-9942-11e9-9cd2-86061a909544',
            secretKey: 'sec-c-MGQ1NWYxZGItYzc0Mi00MGE4LWIwNDEtZTBlNzRlYTAxMDRj'
        });
        this.pubnub.init(this);
    }
    // createNotification = (type) => {
    //     return () => {
    //       switch (type) {
    //         case 'success':
    //           NotificationManager.success('Success message', 'Bạn đã xác nhận thanh toán');
    //           break;
    //       }
    //     };
    // }
    handleSubmit = async(ev) => {
        ev.preventDefault();
        var products=[];
        var {customer} = this.props
        var cart_products = this.props.products 
        var item ={}
        for( var  i = 0; i < cart_products.length;i++) {
            item = {
                    id: cart_products[i].id,
                   qty: cart_products[i].quanity
            }
            products.push(item)
        }
        var data = {}
        
        var token = await this.props.stripe.createToken({type: 'card'})
        console.log("Token" ,token.token.id)
        data = {
            name: customer.name,
            phone: customer.phone,
            email: customer.email,
            address: customer.address,
            card_tok: token.token.id,
            postcode:"90000",
            products:products
        }
        
        apiCaller("payments/checkout","POST",data).then(res=>{
            localStorage.removeItem("CART")
            swal("Thanh toán thành công","","success");
            this.setState({
                isRedirect:true
            })
            
            this.pubnub.publish({
                message: {
                    title : "NewOrder"
                },
                channel: 'order'
            });
            
            this.pubnub.publish({
                message: {
                    title : "DeleteInCart"
                },
                channel: 'cart'
            });
        })
        
        }

    render() {
        if(this.state.isRedirect) {
            return <Redirect to="/" />
        }
        return (
            <form onSubmit={this.handleSubmit} action="/charge" method="post" id="payment-form">

                <div className="form-row" 
                // style={{height:"10px"}}
                >
                    <div className="col-md-6">
                        <div>
                            <label for="card-element">
                                Thông tin thẻ
                            </label>
                            <CardElement
                                hidePostalCode={true}
                                style={{
                                base: {
                                    fontSize: '18px'
                                }
                            }}/>

                        </div>

                        <button 
                        // onClick={this.createNotification('success')}
                        >Xác nhận thanh toán</button>
                    </div>

                </div>

            </form>
        );
    }
}

export default injectStripe(CheckoutForm);