import React, { Component } from 'react'

export default class Breadcrumb extends Component {
    render() {
        return (
          
               
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><i>Trang chủ</i></li>
                        <li className="breadcrumb-item"><i>{this.props.title}</i></li>
                        <li className="breadcrumb-item active">{this.props.sub_title}</li>
                    </ol>
                </nav>
    

        )
    }
}
