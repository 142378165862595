import React, {Component} from 'react'
import './app.scss'
import $ from 'jquery';
import 'bootstrap'
import PubNubReact from 'pubnub-react'
export default class ChatSupport extends Component {
    constructor(props) {
        super(props);
        this.pubnub = new PubNubReact({publishKey: 'pub-c-d04eee1c-f283-4557-b435-fde8d17b5976', subscribeKey: 'sub-c-b790c38c-9942-11e9-9cd2-86061a909544', secretKey: 'sec-c-MGQ1NWYxZGItYzc0Mi00MGE4LWIwNDEtZTBlNzRlYTAxMDRj'});
        this.pubnub.init(this);
        this.state = {
            message:0
        }

    }
    checkMess = (message) => {
        var qty = this.state.message++
        this.setState({
            message:qty
        })
        var messagesContainer = $('.messages');
        messagesContainer.append(['<li class="self">', message.message, '</li>'].join(''));
    }
    componentWillMount() {
        this.pubnub.addListener({
            message: (message) => {
                this.checkMess(message.message)
            }
        })
        
        this.pubnub.subscribe({
            channels: ['customer']
        });
      }
    
    
    componentDidMount() {
        var element = $('.floating-chat');
        var myStorage = localStorage;

        // if (!myStorage.getItem('chatID')) {
        //     myStorage.setItem('chatID', this.createUUID());

       // }
        setTimeout(function () {
            element.addClass('enter');
        }, 1000);   
    }
    
    
    openElement=() => {
        var btnChat = $('#btnChat');
        btnChat.hide()
        var element = $('.floating-chat');
        var messages = $('.messages');
        var textInput = $('.text-box');
        $('>i').hide();
        //$('.chat').removeClass('enter').hide();
        element.addClass('expand');
        $('.chat').addClass('enter');
        var strLength = textInput.val().length * 2;
        //textInput.keydown(this.onMetaAndEnter).prop("disabled", false).focus();
        //element.off('click',this.openElement());
        $('.header button').click(this.closeElement);
        $('#sendMessage').click(this.sendNewMessage);
        messages.scrollTop(messages.prop("scrollHeight"));
    }
    closeElement=()=> {
        var element = $('.floating-chat');
        $('.chat').removeClass('enter').hide();
        $('>i').show();
        element.removeClass('expand');
        $('.header button').off('click', this.closeElement);
        $('#sendMessage').off('click', this.sendNewMessage);
        $('.text-box').off('keydown', this.onMetaAndEnter).prop("disabled", true).blur();
        setTimeout(function() {
            $('.chat').removeClass('enter').show()
            element.click(this.openElement);
        }, 500);
        var btnChat = $('#btnChat');
        btnChat.show()
    }
    sendNewMessage=()=> {
        var userInput = $('.text-box');
        var newMessage = userInput.html().replace(/\<div\>|\<br.*?\>/ig, '\n').replace(/\<\/div\>/g, '').trim().replace(/\n/g, '<br>');
        if (!newMessage) 
            return;
        var messagesContainer = $('.messages');
        messagesContainer.append(['<li class="other">', newMessage, '</li>'].join(''));
        console.log("mess",newMessage)
        // clean out old message
        userInput.html('');
        // focus on input
        userInput.focus();
        this.pubnub.publish({
            message: {
                message: newMessage
            },
            channel: 'admin'
        });

        messagesContainer.finish().animate({
                scrollTop: messagesContainer.prop("scrollHeight")
            }, 250);
    }
    onMetaAndEnter=(event) =>{
        if ((event.metaKey || event.ctrlKey) && event.keyCode == 13) {
            this.sendNewMessage();
        }
    }

    render() {
        return (
            
                
                <div className="floating-chat" style={{backgroundColor:"red"}}>
                <div className="row">
                
                <button id="btnChat" onClick={this.openElement}>

                    <i className="fa fa-comments" aria-hidden="true"></i>
                     {
                         (this.state.message > 0 ? <span className="badge-danger">&nbsp;{this.state.message}&nbsp;</span>
                         : "")
                     }
                    
                
                </button>
                </div>
               
              
                <div className="chat">
                    <div className="header">
                        <span className="title">
                            Hỗ trợ khách hàng
                        </span>
                        <button>
                            <i className="fa fa-times" aria-hidden="true"/>
                        </button>
                    </div>
                    <ul className="messages">
                        {/* <li className="other">asdasdasasdasdasasdasdasasdasdasasdasdasasdasdasasdasdas</li>
                        <li className="other">Are we dogs??? 🐶</li>
                        <li className="self">no... we're human</li>
                        <li className="other">are you sure???</li>
                        <li className="self">yes.... -___-</li>
                        <li className="other">if we're not dogs.... we might be monkeys 🐵</li>
                        <li className="self">i hate you</li>
                        <li className="other">don't be so negative! here's a banana 🍌</li>
                        <li className="self">......... -___-</li> */}
                        
                    </ul>
                    <div className="footer">
                        
                    <div className="text-box" contenteditable="true" disabled="true"></div>
                        <button id="sendMessage">send</button>
                    </div>
                </div>
            </div>
            
            

        )
    }
}
