import React, {Component} from 'react'
import Banner from './Banner';
import apiCaller from '../../utils/apiCaller';
import {NavLink} from 'react-router-dom'
import Shopping from './Shopping';


export default class MainNavigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            category: [],
            sub_category: []
        }
    }
    
    
    componentDidMount() {
        apiCaller("categories/list?limit=8&page=1", "GET").then(res => {
            this.setState({category: res.data.docs})
        })
    }

    render() {
        var {category} = this.state;
        var map_category = []
        var map_subcategory = []
        if (category.length > 0) {
            map_category = category.map((cate, index) => {

                if (cate.sub_category.length < 0) {
                    return (
                        <li>
                            <NavLink to="/sanpham/">{cate.name}<i className="fas fa-chevron-right"/></NavLink>
                        </li>
                    )

                } else {
                    map_subcategory = cate.sub_category.map((sub,index) => {
                            return (
                                <li key={index}>
                                    {/* <a onClick={()=>this.filterCategory(cate.name)}>{sub.name}<i className="fas fa-chevron-right"/>
                                    </a> */}
                                    <NavLink to={'/sanpham/'+sub.name.split(" ").join("-")}>{sub.name}<i className="fas fa-chevron-right"/></NavLink>
                                </li>

                            )
                        })
                    return (
                        <li key={index} className="hassubs">
                            <NavLink to={'/sanpham/'+cate.name.split(" ").join("-")}>{cate.name}<i className="fas fa-chevron-right"/></NavLink>
                            <ul>
                                {map_subcategory}
                            </ul>
                        </li>
                    )
                }

            })

        }
        return (
            
                <div>
                    <nav className="main_nav">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="main_nav_content d-flex flex-row">
                                        {/* Categories Menu */}
                                        <div className="cat_menu_container">
                                            <div
                                                className="cat_menu_title d-flex flex-row align-items-center justify-content-start">
                                                <div className="cat_burger"><span/><span/><span/></div>
                                                <div className="cat_menu_text">Danh mục sản phẩm</div>
                                            </div>
                                            <ul className="cat_menu">
                                                {map_category}
                                            </ul>
                                        </div>
                                        {/* Main Nav Menu */}
                                        <div className="main_nav_menu ml-auto">
                                            <ul className="standard_dropdown main_nav_dropdown">
                                                <li>
                                                <NavLink to={"/"}>Trang chủ<i className="fas fa-chevron-down"/></NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={"/sanpham"}>Danh sách sản phẩm<i className="fas fa-chevron-down"/></NavLink>
                                                </li>
                                                <li>
                                                    <a href="blog.html">Về chúng tôi<i className="fas fa-chevron-down"/></a>
                                                </li>
                                                <li>
                                                    <a href="contact.html">Liên hệ<i className="fas fa-chevron-down"/></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <Banner/>
                    <div>


                    </div>

                </div>

        )
    }
}
