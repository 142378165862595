import React, { Component } from 'react'
import './shop_styles.css'
import apiCaller from '../../utils/apiCaller'
import { NavLink } from 'react-router-dom'
import Breadcrumb from './Breadcrumb';
import scrollToComponent from 'react-scroll-to-component';
import Currency from 'react-currency-formatter';
import PubNubReact from 'pubnub-react'
import swal from 'sweetalert'
import Facebook from './Facebook';


export default class Shopping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: [],
            sub_category: [],
            brand: "",
            docs: [],
            currentPage: 1,
            totalDocs: 0,
            isHome: false,
            message:false
        }
        this.pubnub = new PubNubReact({
            publishKey: 'pub-c-d04eee1c-f283-4557-b435-fde8d17b5976',
            subscribeKey: 'sub-c-b790c38c-9942-11e9-9cd2-86061a909544',
            secretKey: 'sec-c-MGQ1NWYxZGItYzc0Mi00MGE4LWIwNDEtZTBlNzRlYTAxMDRj'
        });
        this.pubnub.init(this);
        this.componentWillMount = this.componentWillMount.bind(this)
    }
    checkMess = (message) => {
        this.setState({
            message:message
        })
    }
    
    componentWillMount() {
        this.pubnub.addListener({
            message: (message) => {
                this.checkMess(message)
            }
        })
        
        this.pubnub.subscribe({
            channels: ['customer']
        });
      }
    
    componentWillReceiveProps(nextProps) {
        if (nextProps.match !== this.props.match) {
            scrollToComponent(this.Green, { offset: 0, align: 'middle', duration: 500, ease: 'inExpo' })
            var category = nextProps.match.params.category;
            apiCaller("products/list?is_active=1&limit=100&search=" + category, "GET").then(res => {
                this.setState({ docs: res.data.docs, isHome: false })
            })
        }


    }
    updateAPI = () => {
        apiCaller("products/list?is_active=1&page=1&limit=12", "GET").then(res => {
            this.setState({ docs: res.data.docs, currentPage: 1, totalDocs: res.data.totalDocs,isHome: true })
        })
    }

    componentDidMount() {
        if (this.props.match.params.category) {
            scrollToComponent(this.Green, { offset: 0, align: 'top', duration: 500, ease: 'inExpo' })
            var category = this.props.match.params.category;
            apiCaller("products/list?is_active=1&limit=100&search=" + category, "GET").then(res => {
                this.setState({ docs: res.data.docs })
            })
        }
        else {
            scrollToComponent(this.Green, { offset: 0, align: 'top', duration: 500, ease: 'inExpo' })
            apiCaller("categories/list?limit=8&page=1", "GET").then(res => {
                this.setState({ category: res.data.docs })
            })
            apiCaller("products/list?is_active=1&page=1&limit=12", "GET").then(res => {
                this.setState({ docs: res.data.docs, currentPage: 1, totalDocs: res.data.totalDocs,isHome: true })
            })
        }

    }

    expandClick = () => {

        this.setState({
            currentPage: this.state.currentPage + 1
        })
        var id = this.state.currentPage;
        var new_docs = this.state.docs
        var expand = []
        apiCaller(`user/products/list?page=${id}&limit=12&is_active=1&status=selling`, "GET").then(res => {
            expand = res.data.docs
            this.setState({
                docs: new_docs.concat(expand)
            })
        })

    }
    onAddToCart = (product) => {
        // var index = -1
        // index = findProductInCart(product)
         var s_product = {
             id : product._id,
             title: product.title,
             price : product.price,
             desciption : product.desciption,
             quanity : 1,
             images : product.images[0]
         }
         if(localStorage.getItem('CART')) {
             var store = JSON.parse(localStorage.getItem('CART'))
             store.push(s_product);
             localStorage.setItem("CART",JSON.stringify(store))
             swal({
                text: "Bạn đã thêm sản phẩm vào giỏ hàng thành công",
                icon: "success"
              });
         }
         else {
             var new_store = []
             new_store.push(s_product)
             localStorage.setItem("CART",JSON.stringify(new_store))
             swal({
                text: "Bạn đã thêm sản phẩm vào giỏ hàng thành công",
                icon: "success"
              });
         }
         this.pubnub.publish({
            message: {
                title : "AddToCart",
                qty:1
            },
            channel: 'cart'
        });
     }

   

    render() {
        if(this.state.message) {
            this.updateAPI()
        }
        var { category } = this.state;
        var map_category = []
        var map_subcategory = []
        if (category.length > 0) {
            map_category = category.map((cate, index) => {

                if (cate.sub_category.length < 0) {
                    return (
                        <li>
                            <span onClick={() => this.filterCategory(cate.name)}>{cate.name}</span>
                        </li>
                    )

                } else {
                    map_subcategory = cate.sub_category.map((sub, index) => {
                        return (
                            <i key={index} className="dropdown-item">{sub.name}</i>
                        )
                    })
                    return (
                        <div key={index} className="btn-group">
                            <button
                                style={{ backgroundColor: "#0E8CE4" }}
                                type="button"
                                className="btn btn-info dropdown-toggle"
                                data-toggle="dropdown">
                                {cate.name}
                            </button>
                            <div className="dropdown-menu">
                                {map_subcategory}

                            </div>

                        </div>
                    )
                }

            })

        }
        
        var products = []
        products = this.state.docs
        var map_products = []
        var discount = 0
        if (products.length > 0) {
            map_products = products.map((product, index) => {
                discount = product.price * (1 - (product.discount / 100))

                return (

                    <div key={index} className="col-lg-3 col-md-6 mb-r">
                        <div  className="card text-center card-cascade narrower">
                            <div
                                style={{
                                height: "200px",
                                width: "250px",
                                backgroundColor:"white"
                            }}
                                className="view overlay hm-white-slight z-depth-1">

                                {product.images.length > 0
                                    ? <img
                                           
                                            width="70%"
                                            style={{
                                            height: "70%",
                                            marginLeft: "auto",
                                            marginRight: "auto"
                                        }}
                                            src={product.images[0]}
                                            className="img-fluid"
                                            alt={product.title}/>
                                    :<img
                                        width="70%"
                                        style={{
                                        height: "70%",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        display: "block"
                                    }}
                                        src="https://via.placeholder.com/200x150?text=no_image"
                                        className="img-fluid"
                                        alt={product.title}/>
}

                                <span>
                                    <div className="mask waves-light waves-effect waves-light"></div>
                                </span>
                            </div>
                            <div className="card-body">
                                <h4 className="card-title">
                                    <strong>
                                        <NavLink to={"/chitietsanpham/" + product.id}>
                                            {product.title}</NavLink>
                                    </strong>
                                </h4>
                            </div>
                                {product.desciption}
                            <div className="card-footer">
                                <div className="row">
                                    <span style={{margin:"0 auto"}} className="center text">
                                        <del><Currency
                                            quantity={product.price}
                                            currency="VND"
                                            locale="vi_VN"
                                            pattern="###,### !"
                                            decimal=","
                                            group="."/></del>
                                    </span>
                                    
                                </div>
                                <div className="row">
                                    
                                        <span style={{margin:"0 auto"}} className="center text-danger">
                                        <Currency
                                            quantity={discount}
                                            currency="VND"
                                            locale="vi_VN"
                                            pattern="###,### !"
                                            decimal=","
                                            group="."/>
                                    </span>
                                    
                                    
                                </div>

                                <div className="row">
                                
                                        <span style={{margin:"0 auto"}} className="center text">
                                        <button
                                            className="btn-floating blue-gradient"
                                            onClick={() => this.onAddToCart(product)}>
                                            <i className="fa fa-shopping-cart"></i>
                                        </button>
                                    </span>
                                
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                )
            })
        }
        return (
            <section ref={(section) => { this.Green = section; }}>
                <div className="shop">

                    <div className="container">

                        <div className="sidebar_section">

                            <div className="sidebar_title">Danh mục sản phẩm
                            <br /></div>
                            <div className="main_nav_menu ml-auto">

                                {/* <div className="btn-group">
                                    {map_category}
                                </div> */}
                                <br />
                                <br />

                                <Breadcrumb title="Sản phẩm" sub_title={this.state.sub_title} />
                            </div>
                            <hr />
                        </div>
                        <div className="row">

                            <div className="btn-group" role="group" aria-label="Basic example">
                                <NavLink to={"/sanpham/apple"} className="btn btn-primary">Apple</NavLink>
                                <NavLink to={"/sanpham/samsung"} className="btn btn-primary">Samsung</NavLink>
                                <NavLink to={"/sanpham/xiaomi"} className="btn btn-primary">Xiaomi</NavLink>
                                <NavLink to={"/sanpham/sony"} className="btn btn-primary">Sony</NavLink>
                                <NavLink to={"/sanpham/meizu"} className="btn btn-primary">Meizu</NavLink>
                            </div>

                        </div>
                        <br /> <br />
                        <div className="row">
                            <div className="col-lg-12">


                                <div className="row">
                                    {map_products}
                                    <div style={{
                                        align: 'center'
                                    }}>
                                    </div>
                                    {
                                        (this.state.isHome
                                            ?
                                            <div style={{margin:"0 auto"}} className="center">
                                                <button type="button" onClick={this.expandClick} className="btn btn-info">Xem thêm </button>
                                            </div>
                                            : ""
                                        )
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        )
    }
}
